import logo from '../images/logo.png';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import linkedin from '../images/linkedin.svg';
import profile from '../images/profile.png';
import portforlio from '../images/portforlio.png';
import project1 from '../images/project1.png'
import project2 from '../images/project2.png'

const images = {logo, facebook, instagram, linkedin, profile, portforlio, project1, project2};
export default images;
