import React from 'react'
import Project from '../components/project'
import images from '../constants/images'
const Project1 = () => {
  return (
    <Project title="Emma's Kitchen" 
    description="Emma's s Kitchen is a multi-page website that provides both cooking class and private dining service. It's built using React, styled with Tailwind CSS. Due to hosting limitations, I’ve opted for PHP as my backend scripting language. The recipe API is written in PHP, connected to a MySQL database. The language translation functionality is implemented using React i18next. Every element in this website, including buttons and pagination, is built from scratch."
    link="https://emmaskitchen.emmafang.com/"
    image={images.project1}
    />
  )
}

export default Project1