import React from "react";
import { BiLogoCss3, BiLogoHtml5, BiLogoJavascript, BiLogoTailwindCss, BiLogoReact} from "react-icons/bi";
import Nav from "../components/nav";
const Hero = () =>{
    return(
        <div className="header relative ">
            <div className="absolute left-0 top-0 w-full">
                <Nav />
            </div>
            <div className="hero__bgWrpper bg-beige">
            <div className="hero flex items-center justify-center h-screen px-[1rem] md:px-[2rem] sm:gap-[4rem] md:gap-[12rem] m-auto max-w-screen-2xl" id='hero'>
                <div className="hero__content flex flex-col justify-between h-[250px] md:h-[350px]">
                    <h1 className="text-[32px] md:text-[38px] lg:text-[70px] font-light">Hello!</h1>
                    <h1 className="text-[25px] md:text-[40px] lg:text-[62px] font-bold mb-[19px] md:mb-[23px] lg:mb-[40px]">I am Emma Fang</h1>
                    <p className="text-[14px] lg:text-[18px]">I am a passionate front-end web developer based in Copenhagen.</p>
                    <div className="buttons flex gap-8 pt-10">
                        <a href="./EmmaFang.pdf" download>
                            <button className="h-[2rem] w-[8rem] bg-customGrey text-white text-[16px] lg:text-[18px] px-2 rounded-sm hover:bg-black">Download CV</button>
                        </a>
                        <a href="https://emmaskitchen.emmafang.com" target="_blank">
                            <button className="h-[2rem] w-[8rem] bg-customGrey text-white text-[16px] lg:text-[18px] px-2 rounded-sm hover:bg-black">Latest Project</button>
                        </a>
                    </div>
                </div>
                <div className="skills flex">
                    <ul className="flex flex-col justify-between h-[250px] md:h-[350px]">
                        <li><BiLogoCss3 className="text-[30px] md:text-[35px]"/></li>
                        <li><BiLogoHtml5 className="text-[30px] md:text-[35px]"/></li>
                        <li><BiLogoJavascript className="text-[30px] md:text-[35px]"/></li>
                        <li><BiLogoTailwindCss className="text-[30px] md:text-[35px]"/></li>
                        <li><BiLogoReact className="text-[30px] md:text-[35px]"/></li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    )
}
export default Hero;