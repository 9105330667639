import React from 'react'
import Project from '../components/project'
import images from '../constants/images'
const Project2 = () => {
  return (
    <Project title="Axstable" 
    description="Axstable is a website I have been creating for a stable that provides horse training and sales. It’s a an ongoing project built with React and Tailwind CSS."
    link="https://emmafang.com/axstable"
    image={images.project2}
    />
  )
}

export default Project2