import './App.css';
import Hero from './containers/hero';
import Aboutme from './containers/aboutme';
import Contact from './containers/contact';
import Footer from './containers/footer';
import Project1 from './containers/project1';
import Project2 from './containers/project2';
function App() {
  return (
    <div className="App overflow-hidden">
      <Hero />
      <Aboutme />
      <Project1 />
      <Project2 />
      <Contact />
      <Footer />
      
    </div>
  );
}

export default App;
