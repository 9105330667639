const Project = ({title, description, link, image}) => {
    return (
        <div className="portfolio m-auto max-w-screen-2xl flex flex-col gap-8 lg:gap-16 xl:gap-0 md:flex-row w-full text-black py-[2rem]  px-[1rem] sm:px-[2rem] lg:px-[4rem] xl:px-[8rem] md:py-[4rem]" id='portfolio'>
            <div className="portforlio__content flex-1 flex flex-col justify-start items-start ">
                    <h1 className="pb-[1rem] md:pb-[2rem] text-[28px] font-semibold">{title}</h1>
                    <p className="text-justify text-[16px] lg:text-[18px] myFont xl:w-[70%]">{description}</p>
                    <a target="_blank" rel="noreferrer" href={link}>
                        <button className="w-[8rem] h-[2rem] text-white bg-customGrey mt-[2rem] rounded-sm hover:bg-black">See Live</button>
                    </a>
            </div>
            
            <div className="portforlio__image flex-1 flex flex-col justify-center items-center">
                {/* <img src={image} alt="project" className="rounded-md h-[270px] xl:h-auto object-cover"/> */}
                <img src={image} alt="project" className="rounded-md w-full h-[270px] xl:h-[400px] object-cover"/>
            </div>
            
        </div>
    )
}

export default Project;