import images from "../constants/images";
const Aboutme = () => {
    return (
        <div className="aboutme" id='aboutme'>
            <div className="profile m-auto max-w-screen-2xl  flex flex-col md:flex-row text-[#3B373B] py-[2rem] px-[1rem] sm:px-[2rem] lg:px-[4rem] xl:px-[8rem]  md:py-[4rem] gap-8 lg:gap-16 xl:gap-0 lg:gap-0">
                <div className="profile__image flex-1 flex justify-start items-center" >
                    <img src={images.profile} alt="profile" className="w-full xl:w-[70%] h-[270px] xl:h-auto object-cover rounded-md"/>
                </div>
                <div className="profile__content flex-1 flex justify-center items-start">
                    <div>
                    <h1 className="pb-[1rem] md:pb-[2rem] text-[28px] font-semibold">About me</h1>
                    <p className="text-justify text-[#3B373B] text-[16px] lg:text-[18px] myFont">Hello! My name is Emma Fang and I’m a web developer with two years of experience in creating websites. I started learning to code after losing my previous job during the pandemic and fell in love with programming. My first programming language was Python, and I worked on a few projects with it. I’m passionate about cooking and wanted to create a website for my food, which led me to discover my interest in creating websites. I specialize in web development, which includes React, HTML, CSS, JavaScript, Tailwind, Responsive Design and Version Control. I’m experienced in working with Bootstrap and jQuery, and excited to learn other new frameworks.  I strive to create responsive and user-friendly websites that provide a great user experience. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Aboutme;