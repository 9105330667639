import { useState } from "react";
import axios from "axios";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        if (name.length === 0){
            alert("Name has left blank!")
        }
        else if (email.length === 0){
            alert("Email has left blank!")
        }
        else if (message.length === 0){
            alert("Message has left blank!")
        }
        else {
            const url = "https://emmafang.com/contact.php";
            let fData = new FormData();
            fData.append('name', name);
            fData.append('email', email);
            fData.append('message', message);
            axios.post(url, fData).then(response=>{window.location.href = "https://emmafang.com/thankyou.html"}).catch(error=>alert(error));
        }
    }
    return (
        <div className="contact__bgWrapper bg-beige">
        <div className="contact m-auto max-w-screen-2xl flex flex-col gap-8 lg:gap-0 lg:flex-row items-center px-[3rem] py-[6rem] " id='contact'>
            <div className="contact__text flex flex-1 m-auto sm:justify-center items-center">
                <h1 className="text-[44px] font-bold text-black">Contact me</h1>
            </div>
            <div className="contact__form flex flex-1 justify-start px-[2rem] sm:px-[4rem]">
            <form className="py-[3rem] flex flex-col gap-4 px-[4rem] sm:px-[9rem] bg-customGrey rounded-md text-white">
                <div className="form-row flex flex-col">
                    <label htmlFor="name">Name:</label>
                    <input className="text-black pl-2" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="form-row flex flex-col">
                    <label htmlFor="email">Email:</label>
                    <input className="text-black pl-2" type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-row flex flex-col">
                    <label htmlFor="message">Message:</label>
                    <textarea className="text-black pl-2" rows="5" cols="30" id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
                </div>
                <button className="w-[100px] border-solid border-[black] border-[1px] bg-beige hover:bg-white text-black" type="submit" name="send" id="send" value="send" onClick={handleSubmit}>
                    Send
                </button>
            </form>

            </div>

        </div>
        </div>
    )
}
export default Contact;