const Footer = () => {
    return(
        <div>
            <div className="footer flex justify-center items-center">
                    <p className="content-bottom text-[12px] md:text-[15px] leading-[32px] text-center my-5">
                    Copyright © 2024 emmafang. All rights reserved.
                    </p>
                </div>
            </div>
    )
}
export default Footer;


