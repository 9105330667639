import images from "../constants/images";
import { useState } from "react";
import React, {useRef} from "react";
import { FaFacebookSquare,  FaLinkedin, FaWhatsappSquare} from "react-icons/fa";
const Nav = () =>{
    const [hamburgerMenu, setHamburgerMenu] = useState(false);
    function showHamburgerMenu(){
        setHamburgerMenu(!hamburgerMenu);
    }
    const [isX, setIsX] = useState(false);
    const buttonRef = useRef(null);
    function toggleButton(){
        setIsX(!isX);
        const button = buttonRef.current;
        button.classList.toggle('open');
    }
    return(
        <div className="navbar">
            <nav className="flex m-auto max-w-screen-2xl justify-between px-[2rem] sm:px-[4rem]" id="navbar">
                <div className="nav__logo flex self-start">
                    <a href="#home"><img src={images.logo} width="150px" alt="logo"/></a>
                </div>
                <div className="nav__menu text-[#3B373B] myFont text-[20px] hidden md:flex self-center">
                    <ul className="flex">
                    <li className="px-[1rem] hover:opacity-[0.5]"><a href="#hero">Home</a></li>
                    <li className="px-[1rem] hover:opacity-[0.5]"><a href="#aboutme">About me</a></li>
                    <li className="px-[1rem] hover:opacity-[0.5]"><a href="#portfolio">Portforlio</a></li>
                    <li className="px-[1rem] hover:opacity-[0.5]"><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div className="hamburgerButton flex flex-col md:hidden self-center " ref={buttonRef} onClick={()=>{showHamburgerMenu(); toggleButton()}}>
                    <span  className="bg-black h-[3px] w-[30px]  mb-[8px] relative ease-in-out duration-300"></span>
                    <span  className="bg-black h-[3px] w-[30px] mb-[8px] relative ease-in-out duration-300"></span>
                    <span  className="bg-black h-[3px] w-[30px]   relative ease-in-out duration-300"></span>
                </div>
                {hamburgerMenu && (
                <div className={`overlayMenu bg-[#3B373B] text-[#DDDBDE] text-[24px] py-[4rem] absolute top-[150px] left-0 w-full `}>            
                    <ul className="text-center">
                        <li className="pb-[24px] hover:opacity-[0.5]"><a href="#hero">Home</a></li>
                        <li className="pb-[24px] hover:opacity-[0.5]"><a href="#aboutme">About me</a></li>
                        <li className="pb-[24px] hover:opacity-[0.5]"><a href="#portfolio">Portforlio</a></li>
                        <li className="pb-[24px] hover:opacity-[0.5]"><a href="#contact">Contact</a></li>
                    </ul>
                    <div className="social-icon flex justify-center gap-[1.5rem] pt-[4rem]" >
                    <a href="https://www.facebook.com/people/Emma-Fang/pfbid0qZPQmmqWSubLdy6UWjyPP7DhXLqQWA2rXD1YTT5LMwur9wJQs4q53cKvN4rCZMLvl/?mibextid=LQQJ4d" className="hover:opacity-[0.5]"><FaFacebookSquare /></a>
                    <a href="https://www.linkedin.com/in/emma-fang-aa0770197/" className="hover:opacity-[0.5]"><FaLinkedin /></a>
                    <a href="https://api.whatsapp.com/send?phone=4593888155" className="hover:opacity-[0.5]"><FaWhatsappSquare /></a>
                </div>
                </div>
                )}
            </nav>
        </div>
    )
}
export default Nav;